<template>
  <div class="box">
    <div class="content">
      <p class="text">请填写基本信息</p>
      <van-form>
        <van-field v-model="name" name="用户名" placeholder="请填写真实姓名" />
        <van-field
          v-model="mobile"
          name="手机号码"
          placeholder="请填写您的手机号码"
        />
        <div class="sendCode">
          <van-field
            v-model="code"
            name="验证码"
            class="code"
            placeholder="请填写验证码"
          />
          <van-button
            type="primary"
            class="codeBtn"
            v-show="show"
            @click="seedCode"
            >发送验证码</van-button
          >
          <van-button type="primary" class="codeBtn color" v-show="!show">
            {{ count }}s重新获取
          </van-button>
        </div>
        <div>
          <van-button
            round
            block
            type="info"
            native-type="submit"
            @click="onSubmit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Login, sendSms } from "@/api/list";
export default {
  name: "info",
  data() {
    return {
      mobile: "",
      name: "",
      code: "",
      show: true,
      count: "",
      timer: null,
    };
  },
  created() {
    if (localStorage.getItem("access_id")) return this.$router.push("/list");
  },
  methods: {
    onSubmit() {
      let data = {
        mobile: this.mobile,
        name: this.name,
        keyCode: this.code,
      };
      Login(data).then((res) => {
        console.log(res);
        if (res.data.code != 200) {
          return this.$toast({ message: res.data.message });
        } else {
          localStorage.setItem("access_id", res.data.data.list.access_id);
          console.log(localStorage.getItem("access_id"));
          this.$toast({ message: res.data.message });
          setTimeout(() => {
            this.$router.push("/list");
          }, 1500);
        }
      });
    },
    // 发送验证码
    async seedCode() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
      const res = await sendSms({ phone: this.mobile, type1: 1 });
      console.log(res.data.code);
      if (res.data.code != 200)
        return this.$toast({ message: res.data.message });
      this.$toast({ message: res.data.message });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}
.box {
  height: 100vh;
  width: 100%;
  background: url("./../assets/info.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  display: flex;
  justify-content: center;
  .content {
    width: 100%;
    padding: 0 30px;
    padding-top: 45%;
    .text {
      font-weight: bold;
      color: #333333;
      font-size: 22px;
    }
    .van-form {
      width: 90%;
      margin-left: 10px;
      margin-top: 40px;
    }
    .van-cell {
      background: #f3f3f3;
      margin-top: 15px;
      border-radius: 60px;
      padding: 8px 16px;
    }
    .van-button {
      height: 38px;
      background: #6f72fa;
      border-radius: 42px;
      margin: 20px 0;
    }
  }
}
.sendCode {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .van-button {
    margin: 0;
  }
  .codeBtn {
    width: 45%;
    font-size: 13px;
  }
  .van-button--primary {
    border: none;
  }
  .color {
    background: #ccc !important;
  }
}
.code {
  width: 53%;
  height: 40px;
  background: #f3f3f3 !important;
  margin-top: 0 !important;
  border-radius: 60px !important;
  padding: 8px 16px !important;
}
</style>
